import * as HttpService from "./http.service";
import {
  getAuth,
  getCompanyId,
  getCurrentCompanySlug,
  setAuth,
} from "./identity.service";
import {
  ADD_USER_URL,
  GET_USERS_URL,
  GET_USER_BY_ID_URL,
  REFRESH_USER_TOKEN,
  UPDATE_USER_URL,
  USER_LOGIN,
  USER_SESSION,
  USER_LOGOUT,
  GET_ALL_USERS_URL,
  ADD_USER_FROM_ADMIN,
  UPDATE_USER_FROM_ADMIN,
  MAP_RECRUITER,
  GET_USER_BY_ID_NO_AUTH_URL,
  GET_USER_BY_ID_AND_COMPANY_ID_URL,
  GET_COMPANY_BY_USER_ID,
  GET_USER_BY_ROLE_ID_AND_COMPANY_ID,
  VALIDATE_USERS_FROM_CSV_URL,
  ADD_BULK_USERS_FROM_CSV_URL,
} from "./url.service";
import { roleDefaultPage } from "lib/utils";
import { CANDIDATE_ROLE } from "constants";

export const userLogin = (mobileNumber, countryCode, loginType, emailId) => {
  return HttpService.postWithOutAuth(USER_LOGIN, {
    mobileNumber: mobileNumber,
    countryCode: countryCode,
    loginType: loginType,
    emailId: emailId,
  });
};
export const userSession = (
  mobileNumber,
  emailId,
  loginType,
  otp,
  countryCode
) => {
  return HttpService.postWithOutAuth(USER_SESSION, {
    mobileNumber,
    emailId,
    loginType,
    otp,
    countryCode,
  });
};
export const userLogout = (
  user
) => {
  return HttpService.postWithAuth(USER_LOGOUT, user);
};

export const getUsers = (
  comapanyId,
  search = "",
  offset,
  limit,
  filters = "",
  sort
) => {
  return HttpService.getWithAuth(
    GET_USERS_URL(comapanyId, search, offset, limit, filters, sort)
  );
};

export const getAllUsers = (search, filters, offset) => {
  return HttpService.getWithAuth(GET_ALL_USERS_URL(search, filters, offset));
};

export const addUserFromAdmin = (userDetails) => {
  return HttpService.postWithAuth(ADD_USER_FROM_ADMIN, { ...userDetails });
};

export const updateUserFromAdmin = (userId, userDetails) => {
  return HttpService.putWithAuth(UPDATE_USER_FROM_ADMIN(userId), {
    ...userDetails,
  });
};

export const getUserById = (userId) => {
  return HttpService.getWithAuth(GET_USER_BY_ID_URL(userId));
};

export const getUserByUserIdAndCompanyId = (userId, companyId) => {
  return HttpService.getWithAuth(
    GET_USER_BY_ID_AND_COMPANY_ID_URL(userId, companyId)
  );
};

export const getCompaniesByUserId = (userId) => {
  return HttpService.getWithAuth(GET_COMPANY_BY_USER_ID(userId));
};

export const getUserByIdNoAuth = (userId) => {
  return HttpService.getWithOutAuth(GET_USER_BY_ID_NO_AUTH_URL(userId));
};

export const addUser = (companyId, userDetails) => {
  return HttpService.postWithAuth(ADD_USER_URL(companyId), {
    ...userDetails,
  });
};
export const updateUser = (companyId, userId, userDetails) => {
  return HttpService.putWithAuth(UPDATE_USER_URL(companyId, userId), {
    ...userDetails,
  });
};

export const refreshUserToken = (userId, hash, requestFrom="") => {
  return HttpService.postWithOutAuth(REFRESH_USER_TOKEN(userId), { hash, requestFrom });
};

export const checkIfCurrentUserExistOrTokenExpired = async (pageReponse) => {
  const auth = getAuth();
  const companyId = getCompanyId();
  const userResponse = await getUserByUserIdAndCompanyId(auth.id, companyId);

  if (userResponse.status) {
    return userResponse.entity;
  }

  if (userResponse.error) {
    const { status, data } = userResponse.error.response;
    if (status === 401 && data.message === "Token Expired!") {
      const response = await refreshUserToken(auth.id, auth.refreshToken);
      if (response.status) {
        const authData = {
          id: response.entity.id,
          token: response.entity.token,
          refreshToken: response.entity.refreshToken,
        };
        setAuth(authData);
        pageReponse.setHeader(
          "Set-Cookie",
          `AUTH=${JSON.stringify(authData)};path=/;`
        );
        return response.entity;
      }
      return null;
    }
  }
  return null;
};

export const refreshTokenAndSetAuth = async (callback) => {
  const auth = getAuth();
  const response = await refreshUserToken(auth.id, auth.refreshToken);
  if (response.status) {
    setAuth({
      id: res.entity.id,
      token: res.entity.token,
      refreshToken: res.entity.refreshToken,
    });
    const res = await callback();
    return res;
  }
  return { status: false, message: "Request failed" };
};

export const mapRecruiterToRecruiterManager = async ({
  recruiterManagerId,
  recruiterId,
}) => {
  const { id } = getAuth();
  const companyId = getCompanyId();
  return HttpService.postWithAuth(MAP_RECRUITER({ companyId, userId: id }), {
    recruiterManagerId,
    recruiterId,
  });
};
export const deleteRecruiterMapping = async ({ recruiterId }) => {
  const { id } = getAuth();
  const companyId = getCompanyId();
  return HttpService.deleteWithAuth(
    MAP_RECRUITER({ companyId, userId: recruiterId })
  );
};

export const editRecruiterMapping = async ({
  recruiterManagerId,
  recruiterId,
}) => {
  const { id } = getAuth();
  const companyId = getCompanyId();
  return HttpService.putWithAuth(MAP_RECRUITER({ companyId, userId: id }), {
    recruiterManagerId,
    recruiterId,
  });
};

export const getUserByRoleIdAndCompanyId = (roleId, companyId) => {
  return HttpService.getWithAuth(
    GET_USER_BY_ROLE_ID_AND_COMPANY_ID(roleId, companyId)
  );
};

export const adminLoginRedirect = async (router) => {
  
  const auth = getAuth();
  if (auth) {
    if( auth?.roles && auth?.roles[0] === CANDIDATE_ROLE ){
     return candidateLoginRedirect(router)
    }

    const { entity } = await getUserById(auth.id)
    if (entity.isSuperAdmin) {
      router.push('/superadmin');
      return null
    }

    if (!entity.isCompanyAdmin && entity.company.length) {
      let companySlug = await getCurrentCompanySlug(entity)
      const currentCompany = entity.company.find((company) => company.slug === companySlug)
      const path = roleDefaultPage(entity?.roles, entity, currentCompany)
      router.push(path);
      return null
    }
  }
}

export const candidateLoginRedirect = async (router) => {
  const auth = getAuth();

  if(auth && !auth.roles){
    return null
  }
  
  if(auth && auth?.roles[0] === CANDIDATE_ROLE){
    let companySlug = auth.company.slug
    return router.push(`/${companySlug}/dashboard`)
  }
}

export const validateUsersFromCSV = (companyId, csvData) => {
  return HttpService.postWithAuth(VALIDATE_USERS_FROM_CSV_URL(companyId), { csvData });
}

export const addUsersFromCSV = (companyId, csvData) => {
  return HttpService.postWithAuth(ADD_BULK_USERS_FROM_CSV_URL(companyId), { csvData });
}