
import "../styles/globals.css";
import { ToastProvider, useToast } from '../components/ToastContext';
// Below code is commented to use flagsmith at later moment if required
// import { FlagsmithProvider } from "flagsmith/react";
// import flagsmith from "flagsmith/isomorphic";
import { getLayout } from "templates/Layout/LayoutConfiguration";
import { SessionContext } from "../components/SessionContext";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import Head from 'next/head';
import Script from 'next/script';
import { getCompanyId, setCompanyId } from 'services/identity.service';
import CustomToast from "molecules/CustomToast";

// function MyApp({ Component, pageProps, flagsmithState, router, query }) {
function MyApp({ Component, pageProps, router, query }) {
  const companyId = getCompanyId();
  if (query.company && (companyId !== 'undefined' || companyId !== undefined ) && !pageProps?.currentUserDetails?.company.find(c => c.slug === query.company && c.id === companyId)) {
    const currentCompany = pageProps?.currentUserDetails?.company.find(c => c.slug === query.company)
    setCompanyId(currentCompany?.id)
  }
  let layout = getLayout(router.pathname);
  
  return (
    <QueryClientProvider client={new QueryClient()}>
      <SessionContext.Provider value={pageProps?.currentUserDetails}>
      <ToastProvider>
        {/* Below code is commented to use flagsmith at later moment if required */}
        {/* <FlagsmithProvider flagsmith={flagsmith} serverState={flagsmithState}> */}
          <Head>
            <link rel="icon" href="/images/favicon.ico" />
            <title>ANSR Loop</title>
          </Head>
          <Script type="text/javascript"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            (function (c, l, a, r, i, t, y) {
              c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
              t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
              y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
            })(window, document, "clarity", "script", "g8yxsz28xk");
            `,
          }}
          />
          {layout({ children: <Component {...pageProps} /> })}
        {/* </FlagsmithProvider> */}
        <CustomToast {...pageProps} />
      </ToastProvider>
      </SessionContext.Provider>
    </QueryClientProvider>
  );
}

MyApp.getInitialProps = async ({ ctx }) => {
  // Below code is commented to use flagsmith at later moment if required
  // await flagsmith.init({
  //   environmentID: process.env.NEXT_PUBLIC_FLAGSMITH_KEY,
  // });
  // return { flagsmithState: flagsmith.getState(), query: ctx.query };
  return { query: ctx.query };
};

export default MyApp;
