import Cookies from "universal-cookie";
import Router from 'next/router'
import { useContext } from 'react';
import { SessionContext } from 'components/SessionContext';
import dayjs from 'dayjs';

const cookies = new Cookies();

export const getAuth = () => {
  const auth = cookies.get("AUTH");
  return auth;
};

export const setAuth = (authObject) => {
  cookies.set("AUTH", JSON.stringify(authObject), { path: "/", secure: true });
  return authObject;
};

export const setUserLastActivityTS = (authObject) => {
  const currentTS = dayjs().unix();
  cookies.set("USER_LAST_ACTIVITY_TIMESTAMP", dayjs().unix() , { path: "/", secure: true  });
  return currentTS;
};

export const getUserLastActivityTS = () => {
  const userLastActivityTS = cookies.get("USER_LAST_ACTIVITY_TIMESTAMP");
  return userLastActivityTS;
};

export const getCompanyId = () => {
  const companyId = cookies.get("COMPANY_ID");
  return companyId;
};

export const setCompanyId = (companyId) => {
  cookies.set("COMPANY_ID", JSON.stringify(companyId), { path: "/", secure: true  });
  return companyId;
};

export const getCompanyTimeZone = () => {
  const timeZone = cookies.get("COMPANY_TIME_ZONE");
  return timeZone === undefined ? null : timeZone;
};

export const setCompanyTimeZone = (timeZone) => {
  cookies.set("COMPANY_TIME_ZONE", JSON.stringify(timeZone), { path: "/", secure: true });
  return timeZone;
};

export const removeAuth = () => {
  cookies.remove("COMPANY_ID", { path: "/" });
  cookies.remove("AUTH", { path: "/" });
  cookies.remove("COMPANY_TIME_ZONE", { path: "/" });
  cookies.remove("USER_LAST_ACTIVITY_TIMESTAMP", { path: "/" });
  return;
};

export const isInRole = (role, user) => {
  return user.roles && user.roles.includes(role);
};

export const isAuthenticated = (user) => {
  return user != null && user.token;
};

export const isUnauthorizedRequest = (auth) => {
  return !auth || !isAuthenticated(JSON.parse(auth));
};

export const addRequestCookiesToCache = (requestCookies) => {
  try {
    if (requestCookies?.AUTH) {
      const auth = JSON.parse(requestCookies.AUTH);
      setAuth(auth);
    }

    if (requestCookies?.COMPANY_ID) {
      const companyId = JSON.parse(requestCookies.COMPANY_ID);
      setCompanyId(companyId);
    }

    const timeZone = requestCookies?.COMPANY_TIME_ZONE ? JSON.parse(requestCookies.COMPANY_TIME_ZONE) : "";
    setCompanyTimeZone(timeZone);
  } catch (error) {
    console.error("Error parsing JSON:", error);
  }
}

export const removeAuthAndRedirect = (res) => {
  res.setHeader("Set-Cookie", `AUTH=deleted; Max-Age=0;path=/;`);
  return { redirect: { destination: "/admin/login", permanent: false } };
}

export const removeAuthOnInvalidToken = () => {
  const auth = getAuth();
  const company = auth?.company?.slug;
  removeAuth();
  if (company) {
    Router.push({
      pathname: "/[company]/login",
      query: { company },
    });
  } else {
    Router.push('/admin/login');
  }
}



export const currentUserHasRole = (role) => {
  const userDetails = useContext(SessionContext);
  const companyId = getCompanyId();
  const currentCompany = userDetails?.company.find(
    (company) => company.id === companyId
  );
  return currentCompany?.userRoles?.some(
    (userRole) => userRole.Role.name === role
  );
}
export const getCurrentCompanySlug = (userDetails) => {
  const companyId = getCompanyId();
  const currentCompany = userDetails.company.find((company) => company.id === companyId)
  const slug = currentCompany?.slug;
  return slug;
}
export const isCurrentUserLeadership = () => {
  return currentUserHasRole('Leadership')
}


export const isCurrentUserCompanyAdmin = () => {
  return currentUserHasRole('Company Admin')
}

export const isCurrentUserHROps = () => {
  return currentUserHasRole('HR Ops');
}