import { isTopCampaignTemplatesEnabled } from "../constants";
import * as HttpService from "./http.service";
import {
  CREATE_CAMPAIGN_TEMPLATE_URL,
  UPDATE_CAMPAIGN_TEMPLATE_URL,
  GET_CAMPAIGN_TEMPLATES_URL,
  UPDATE_CAMPAIGN_TEMPLATE_STATUS_URL,
  GET_CAMPAIGN_TEMPLATE_REPORTS,
  GET_TOP_CAMPAIGN_TEMPLATES,
} from "./url.service";

export const addCampaignTemplate = (
  companyId,
  {
    name,
    description,
    designationId,
    publishedStatus,
    touchPoints,
    internalTouchPoints,
  }
) => {
  return HttpService.postWithAuth(CREATE_CAMPAIGN_TEMPLATE_URL(companyId), {
    name,
    description,
    designationId,
    publishedStatus,
    touchPoints,
    internalTouchPoints,
  });
};
export const updateCampaignTemplate = (
  templateId,
  {
    name,
    description,
    designationId,
    publishedStatus,
    touchPoints,
    internalTouchPoints,
  }
) => {
  return HttpService.putWithAuth(UPDATE_CAMPAIGN_TEMPLATE_URL(templateId), {
    name,
    description,
    designationId,
    publishedStatus,
    touchPoints,
    internalTouchPoints,
  });
};
export const getCampaignTemplates = (
  companyId,
  offset,
  search = "",
  sort,
  filters = "",
  status
) => {
  return HttpService.getWithAuth(
    GET_CAMPAIGN_TEMPLATES_URL(companyId, offset, search, sort, status, filters)
  );
};
export const getCampaignTemplate = (templateId) => {
  return HttpService.getWithAuth(UPDATE_CAMPAIGN_TEMPLATE_URL(templateId));
};

export const updateCampaignTemplateStatus = (templateId, { deactivate }) => {
  return HttpService.putWithAuth(
    UPDATE_CAMPAIGN_TEMPLATE_STATUS_URL(templateId),
    {
      deactivate,
    }
  );
};

export const getCampaignTemplateReports = (companyId, search, filters) => {
  return HttpService.getWithAuth(
    GET_CAMPAIGN_TEMPLATE_REPORTS(companyId, search, filters)
  );
};

export const getTopCampaignTemplates = (companyId, filters, limit) => {
  return isTopCampaignTemplatesEnabled
    ? HttpService.getWithAuth(
        GET_TOP_CAMPAIGN_TEMPLATES(companyId, filters, limit)
      )
    : { status: true, entity: [] };
};
