import React, { useState } from "react";
import PropTypes from "prop-types";
import Image from "next/image";

export const useToast = () => {
  const [toast, setToast] = useState({
    open: false,
    message: "",
    type: "",
  });

  const showToast = ({ message = "", timeout = 3000, type }) => {
    setToast({
      message: message,
      open: true,
      type: type,
    });

    setTimeout(() => {
      closeToast();
    }, timeout);
  };

  const closeToast = () => {
    setToast({
      message: "",
      open: false,
    });
  };

  const showToastByResponse = ({ response, message, timeout = 3000, type }) => {
    setToast({
      message: message || response?.message,
      open: true,
      type: type || response?.status ? "success" : "error",
    });

    setTimeout(() => {
      closeToast();
    }, timeout);
  };

  return { toast, showToast, closeToast, showToastByResponse };
};

const configConst = {
  error: {
    image: "/images/icons/ban-icon.svg",
    bgColor: "bg-errorLight",
    textColor: "text-error",
  },
  success: {
    image: "/images/icons/check-circle.svg",
    bgColor: "bg-successLight",
    textColor: "text-success",
  },
   warning: {
    image: "/images/icons/warning.png",
    bgColor: "bg-warningLight",
    textColor: "text-warning",
  }
};

const APIResponseToast = ({ toast }) => {
  const { open = false, message = "", type = "error" } = toast;

  const {
    image = "",
    bgColor = "",
    textColor = "",
  } = configConst[type] || configConst.error;
  return (
    <div
      style={{
        display: open ? "flex" : "none",
      }}
      className={`fixed right-3 top-10 text-sm z-100 w-fit p-5 font-normal rounded ${bgColor} ${textColor}`}
    >
      <span className="flex items-center mr-3">
        <Image
          id="img_tag_55"
          src={image}
          width="22"
          height="22"
          alt={`Image not Found`}
        />
      </span>
      {message}
    </div>
  );
};

export default APIResponseToast;

APIResponseToast.propTypes = {
  toast: PropTypes.objectOf({
    open: PropTypes.bool,
    message: PropTypes.string,
    type: PropTypes.string,
  }),
};
