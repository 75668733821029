import React from "react";
import PropTypes from "prop-types";
import Image from "next/image";

const SidebarListItems = ({
  menuItem,
  menuIconNormalSrc,
  menuIconHoverSrc,
  menuIconAlt,
  menuActive,
  isIconAvail = true,
  onClick,
  href,
}) => {
  return (
    <a
      onClick={onClick}
      href={href}
      className={`flex items-center rounded-lg p-3 mb-2 cursor-pointer hover:bg-gray-50 sidebar-list-item ${
        menuActive && "bg-primary-50 hover:bg-primary-50"
      }`}
    >
      <li className={`flex`}>
        {isIconAvail && (
          <div className="flex items-center mr-4 shrink-0">
            {!menuActive ? (
              <Image
                id="img_tag_38"
                src={menuIconNormalSrc}
                width={24}
                height={24}
                alt={menuIconAlt}
              />
            ) : (
              <Image
                id="img_tag_39"
                src={menuIconHoverSrc}
                width={24}
                height={24}
                alt={menuIconAlt}
              />
            )}
          </div>
        )}
        <div
          className={`text-base  ${
            menuActive ? "text-primary-600" : "text-gray-500"
          }`}
        >
          {menuItem}
        </div>
      </li>
    </a>
  );
};

export default SidebarListItems;

SidebarListItems.propTypes = {
  menuItem: PropTypes.string,
  menuIconNormalSrc: PropTypes.string,
  menuIconHoverSrc: PropTypes.string,
  menuIconAlt: PropTypes.string,
  menuActive: PropTypes.bool,
  isIconAvail: PropTypes.bool,
  onClick: PropTypes.func,
};
