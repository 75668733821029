import React from "react";
import PropTypes from "prop-types";
import SlideOver from "./SlideOver";
import Heading from "atoms/Heading";
import Image from "next/image";
import { useRouter } from 'next/router';

const SelectCompanySlideOver = ({
  isSelected,
  companyList = [],
  onCompanySelect,
  isOpen,
  onClose,
  isSuperAdmin,
  onClickSuperAdmin
}) => {
  const router = useRouter();
  return (
    <SlideOver
      panelWidth="max-w-65.5"
      isOpen={isOpen}
      onClose={onClose}
      contentPosition="left-0"
      enterFrom="-translate-x-full"
      leaveTo="-translate-x-full"
    >
      <div className="p-0 overflow-auto">
        {isSuperAdmin && <div
          className={`flex items-center pt-4 px-6 pb-5 border-b border-gray-100 cursor-pointer  hover:bg-primary-50 last:border-0 ${isSelected && "bg-primary-50"
            }`}

          onClick={ onClickSuperAdmin}
        >
          <Heading type="h6" className="text-base font-medium">
            Super Admin
          </Heading>
        </div>
        }
        {companyList.map((company) => (
          <div
            className={`flex items-center pt-4  px-6 pb-5 border-b border-gray-100 cursor-pointer  hover:bg-primary-50 last:border-0 ${isSelected && "bg-primary-50"
              }`}
            key={company.id}
            id={company.id}
            onClick={() => onCompanySelect(company)}
          >
            {/* <div className="flex items-center shrink-0 mr-2">
              <Image id='img_tag_119'
                loader={({ src }) => src}
                src={company.logo}
                width={64}
                height={30}
                alt={company.companyName}
              />
            </div> */}
            <Heading type="h6" className="text-base font-medium">
              {company.companyName}
            </Heading>
            {isSelected && (
              <div className="absolute right-7">
                <Image id='img_tag_120'
                  src="/images/icons/tick-o.svg"
                  width={11}
                  height={7}
                  alt={company.companyName}
                />
              </div>
            )}
          </div>
        ))}
        
      </div>
    </SlideOver>
  );
};

export default SelectCompanySlideOver;
SelectCompanySlideOver.propTypes = {
  isSelected: PropTypes.bool,
  companyLists: PropTypes.array,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
