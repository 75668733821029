import EmptyLayout from "./EmptyLayout";
import Layout from "./Layout";
import { useRouter } from 'next/router';
import { getAuth, getUserLastActivityTS, removeAuth, setUserLastActivityTS } from "services/identity.service";
import { sessionExpiryTimeInSec } from "constants";
import dayjs from 'dayjs';

const layoutConfig = [
  {
    path: "/admin/login",
    layout: EmptyLayout,
  },
  {
    path: "/login",
    layout: EmptyLayout,
  },
  // {
  //   path: "/[company]/admin/schedule/[userId]",
  //   layout: EmptyLayout,
  // },
  {
    path: "",
    layout: EmptyLayout,
  },
];

const getLayout = (path) => {
  const router = useRouter();
  let shouldRenderComponent = true;

  // Below code is to check if the user session is expired or not
  const auth = getAuth();
  if (auth) {
      const userLastActivityTS = getUserLastActivityTS();
      let timestampDiff;
      if (userLastActivityTS) {
        timestampDiff = dayjs().unix() - userLastActivityTS;
      }
      if (userLastActivityTS && (timestampDiff > sessionExpiryTimeInSec)) {
        removeAuth();
        shouldRenderComponent= false;
      } else {
        setUserLastActivityTS(auth);
      }
  }

  if (shouldRenderComponent) {
    // Rendering the component
    let config = layoutConfig.find((conf) => path == conf.path);
    if (config) return config.layout;
    else if (path.includes("admin")) return Layout;
    else return EmptyLayout;
  } else {
    // Only redirection to login page - no component rendering
    if (auth?.roles?.includes("Candidate")) {
      const company = auth?.company?.slug;
      router.push({
        pathname: "/[company]/login",
        query: {
            company: company,
        },
      });
    } else {
      router.push("/admin/login");
    }

    // Return blank fragement to avoid rendering anything while navigation happens
    return () => (<></>); 
  }
};

export { getLayout };
