import { useEffect } from "react";
import PropTypes from "prop-types";
import { useToast } from "../components/ToastContext";
import Image from "next/image";

const CustomToast = ({
  autoDeleteTime
}) => {
  const { toast, setToast } = useToast();

  useEffect(() => {
    let timer;
    if (toast) {
      const toastAutoDeleteTime = toast.autoDeleteTime || autoDeleteTime || 1000;
      timer = setTimeout(() => {
        setToast(null);
      }, toastAutoDeleteTime);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [toast, setToast, autoDeleteTime]);

  return (
    <>
      {toast && (
        <div
          className={`fixed right-0 top-10 text-sm w-full z-100 ${toast.maxWidth} ${toast.alignType} ${toast.fontWeight} ${toast.padding} ${toast.bgColor} ${toast.textColor} ${toast.borderRadius}`}
        >
          {toast.isShowImg && (
            <span className="flex items-center mr-3">
              <Image
                src={`${toast.toasterIconSrc}`}
                width="22"
                height="22"
                alt={`${toast.toasterIconAlt}`}
              />
            </span>
          )}
          {toast.message}
        </div>
      )}
    </>
  );
};

CustomToast.defaultProps = {
    isShowImg: false,
    maxWidth: "max-w-full",
    alignType: "text-center",
    fontWeight: "font-light",
    borderRadius: "rounded-none",
    onDelete: () => {},
  };

CustomToast.propTypes = {
  body: PropTypes.node,
  isShowImg: PropTypes.bool,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  maxWidth: PropTypes.string,
  alignType: PropTypes.string,
  fontWeight: PropTypes.string,
  padding: PropTypes.string,
  borderRadius: PropTypes.string,
  toasterIconSrc: PropTypes.string,
  toasterIconAlt: PropTypes.string,
  autoDeleteTime: PropTypes.number,
  onDelete: PropTypes.func,
};



export default CustomToast;
