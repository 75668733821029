import { useEffect, useState } from "react";
import { getCompanyId } from "services/identity.service";
import Header from "./Header";
import { getSignedUrl } from "services/aws-uploader.service";

const CompanyHeader = ({
  userDetails,
  isLogoAvail,
  onLogOut,
  isTitleAvail,
}) => {
  const currentCompanyId = getCompanyId();
  const [companies, setCompanies] = useState([]);
  useEffect(() => {
    (async () => {
      const companiesWithSignedImages = userDetails?.company.map((a) => {
        return { ...a };
      });

      for (const element of companiesWithSignedImages) {
        element.logo =
          element.logo !== "" ? await getSignedUrl(element.logo) : "";
      }
      setCompanies(companiesWithSignedImages);
    })();
  }, []);
  const currentCompanyDetails =
    companies.length > 0
      ? companies.find((company) => company.id === currentCompanyId)
      : {};

  return (
    <>
      <Header
        containerWidth="max-w-full"
        padding="py-6 px-4"
        className=""
        isLogoAvail={isLogoAvail}
        onLogOut={onLogOut}
        userDetails={userDetails}
        headerImgSrc={currentCompanyDetails?.logo}
        headerImgWidth={38}
        headerImgHeight={38}
        isTitleAvail={isTitleAvail}
        title={currentCompanyDetails?.companyName}
        companyDetails={currentCompanyDetails}
      />
    </>
  );
};

export default CompanyHeader;
