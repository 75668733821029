import * as HttpService from "./http.service";
import {
  GET_COMPANIES_URL,
  ADD_COMPANY_URL,
  GET_COMPANY_URL,
  UPDATE_COMPANY_CUSTOM_ATTRIBUTE_URL,
  GET_COMPANY_BY_USERID_URL,
  UPDATE_COMPANY_THEMES,
  GET_COMPANY_NO_AUTH,
} from "./url.service";

export const getCompanies = (
  search = "",
  offset = 0,
  limit = undefined,
  filters,
  includeUsers
) => {
  return HttpService.getWithAuth(
    GET_COMPANIES_URL(search, offset, limit, filters, includeUsers)
  );
};

export const addCompany = (companyDetails) => {
  return HttpService.postWithAuth(ADD_COMPANY_URL, { ...companyDetails });
};

export const getCompany = (companyId) => {
  return HttpService.getWithAuth(GET_COMPANY_URL(companyId));
};

export const getCompanyNoAuth = (slug) => {
  return HttpService.getWithOutAuth(GET_COMPANY_NO_AUTH(slug));
};

export const updateCompany = (companyId, companyDetails) => {
  return HttpService.putWithAuth(GET_COMPANY_URL(companyId), {
    ...companyDetails,
  });
};
export const updateCompanyCustomAttribute = (
  companyId,
  customAttributeConfig
) => {
  return HttpService.putWithAuth(
    UPDATE_COMPANY_CUSTOM_ATTRIBUTE_URL(companyId),
    { customAttributeConfig }
  );
};

export const updateCompanyThemes = (companyId, theme) => {
  return HttpService.putWithAuth(UPDATE_COMPANY_THEMES(companyId), { theme });
};
