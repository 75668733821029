import { createContext, useState, useContext } from 'react';

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const [toast, setToast] = useState(null);

  const showToast = (message, options = {}) => {
    setToast({ message, ...options });
  };

  return (
    <ToastContext.Provider value={{ toast, setToast, showToast }}>
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);

export const successOptions = () => {
    return {
        bgColor: "bg-successLight",
        textColor: "text-success",
        maxWidth: "max-w-xs",
        alignType: "flex text-left",
        fontWeight: "font-normal",
        padding: "p-5",
        borderRadius: "rounded",
        toasterIconSrc: "/images/icons/check-circle.svg",
        toasterIconAlt: "Download",
        isShowImg: true,
        autoDeleteTime: 3000
    };
}; 

export const errorOptions = () => {
    return {
        bgColor: "bg-red-300",
        textColor: "text-red-800",
        maxWidth: "max-w-xs",
        alignType: "flex text-left",
        fontWeight: "font-normal",
        padding: "p-5",
        borderRadius: "rounded",
        toasterIconSrc: "/images/icons/time-circle.svg",
        toasterIconAlt: "Download",
        isShowImg: true,
        autoDeleteTime: 3000
    };
};
